<script setup>

</script>

<template>
<div class="honour">
  <h2>资质荣誉</h2>
  <div class="honour_display">
    <img src="@/assets/about_temple/honor_1.jpg" />
    <img src="@/assets/about_temple/honor_2.jpg" />
    <img src="@/assets/about_temple/honor_3.jpg" />
    <img src="@/assets/about_temple/honor.jpg" />
  </div>
</div>
</template>

<style scoped>
.honour{
  width: 90%;
  margin: auto;
}
.honour h2{
  font-size: xx-large;
  width: 20%;
  margin: auto;
}

.honour_display{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.honour_display > img{
  width: 40%;
  margin: 20px;
}
</style>