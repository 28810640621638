<script>
export default {
  name: "online_1001"
}
</script>

<template>
  <div class="online_1001">
    <div class="floating_text">
      <p>自主研发</p>
      <h1>在线式近红外光谱分析仪</h1>
      <h2>PK-Online-1001</h2>
      <p>在线式近红外光谱分析仪，主要用于连续实时分析，仪器安装在生产线上实时分析产线上生产情况，根据分析数据实时反馈进行工艺管控，可以对重要参数进行反馈优化，保证产品良好的一致性。</p>
    </div>
  </div>
</template>

<style scoped>
.online_1001{
  background: url("@/assets/index_page/online_1001.png") no-repeat;
  background-size: cover;
  height: 680px;
  width: 100%;
}
.floating_text{
  position: relative;
  height: 350px;
  width: 440px;
  left: 115px;
  top: 180px;
}
.floating_text > *{
  margin-bottom: 20px;
}
.floating_text h1{
  font-size: 40px;
}
.floating_text p{
  font-size: 18px;
}
</style>