<script>
export default {
  name:"productSeriseTemp",
  props: {
    product_date: {
      type: Object,
      required: true,
    }
  },
  data(){
    return{
      date: this.product_date
    }
  },
  watch:{
    product_date(newVal){
      this.date = newVal
    }
  }
}

</script>

<template>
<div class="series-temp">
  <div class="series-head">
    <img :src="date.picture" class="right"/>
    <div class="left">
      <h3>{{date.title}}</h3>
      <p>{{date.article}}</p>
    </div>
  </div>
  <div class="series-introduction" v-if="date.productIntroduction != null">
    <h4>产品介绍/INTRODUCTION</h4>
    <p v-for="(pp, index) in date.productIntroduction" :key="index">{{pp}}</p>
  </div>
  <div class="series-charactreristic" v-if="date.productCharacteristic != null">
    <h4>产品特点/CHARACTERISTIC</h4>
    <ul>
      <li v-for="(chara, index) in date.productCharacteristic" :key="index">{{chara}}</li>
    </ul>
  </div>
  <div class="application-industry">
    <h4>行业应用/APPLICATION INDUSTRY</h4>
    <p>{{date.applicationIndustry}}</p>
  </div>
</div>
</template>

<style scoped>
.series-head{
  display: flex;
  justify-content: space-between;
}
.series-head > .right{
  width: 35%;
}
.series-head > .left{
  width: 60%;
}
.series-head > .left > h3{
  font-size: xx-large;
  padding-bottom: 40px;
  border-bottom: #63636c solid 3px;
}
.series-head > .left > p{
  margin-top: 30px;
  line-height: 30px;
  color: #63636c;
  font-size: large;
}
.series-introduction, .series-charactreristic, .application-industry{
  margin: 20px auto;
}
.series-introduction > h4, .series-charactreristic > h4, .application-industry > h4{
  font-size: large;
  margin-bottom: 20px;
  background: #63636c;
  color: white;
  padding: 10px 0 10px 10px;
}
.series-introduction > p, .series-introduction > ul .application-industry > p{
  padding: 20px;
  font-size: large;
  line-height: 30px;
  text-indent: 40px;
}
.series-charactreristic > ul > li{
  margin-bottom: 10px;
  font-size: large;
  margin-left: 40px;
}
</style>