<script>
/**
 * 向后端发送请求，请求获取markdown文件
 * 该模板为路由控制才能向后端发送正确的请求
 */
import axios from "axios";
import {marked} from "marked";
import Head_top from "@/components/head_top.vue";
import Head_bottom from "@/components/head_bottom.vue";

export default {
  name: "articleTemplate",
  components: {Head_bottom, Head_top},
  methods:{
    getmarkdownFile(){
      const path = this.backURL + '/article_template/' + this.$route.params.words + '?key=' + this.$route.query.key + "&filetype=markdown";
      axios.get(path).then(res => {
       this.markdownData = res.data;
      }).catch(error => {
        console.log("无法接收到请求", error)
      })
    }
  },
  computed:{
    compiledMarkdown(){
      const processedMarkdown = this.markdownData.replace(
          /!\[([^\]]*)\]\(([^)]+)\)/g,
          (match, altText, imagePath) => {
            // 替换为 require 语句
            // console.log(imagePath) // 查看是否匹配成功
            const requiredPath = this.backURL + "/image/"+this.$route.query.key+"/"+ this.$route.params.words+ "/" + imagePath;
            // console.log(requiredPath) // 查看是否修改成功
            return `![${altText}](${requiredPath})`;
          }
      );
      // console.log(processedMarkdown)  // 查看请求到文档是否成功
      return marked(processedMarkdown);
    }
  },
  created() {
    this.getmarkdownFile();
    // console.log(this.$route.query.key);  // solution
    // console.log(this.$route.params.words)  // 2024_8_28
  },
  data(){
    return{
      markdownData: "# 暂时没有收到请求。。。 ![img1](./img/image002.jpg)",
      backURL: window.globalbackURL,
    }
  }
}

</script>

<template>
  <Head_top />
  <Head_bottom />
  <div v-html="compiledMarkdown" class="markdown">
  </div>
</template>

<style>
.markdown{
  width: 60%;
  margin: auto;
}
.markdown > h1{
  margin: 10px auto 20px 30px;
}
.markdown > h2{
  margin: 10px auto 30px 40px;
}
.markdown > p{
  margin: 10px auto 30px 40px;
  line-height: 40px;
  text-indent: 40px;
}
.markdown > ul, .markdown > ol{
  margin: 10px auto 30px 40px;
}
.markdown > ul > li, .markdown > ol > li{
  margin: auto auto 20px auto;
  line-height: 40px;
}
.markdown img{
  width: 70%;
  margin: auto;
  height: 70%;
}

</style>