<script>
import MapContainer from "@/views/MapContainer.vue";

export default {
  name:"page_footer",
  components: {MapContainer},
  props:{
    if_at_end: Boolean,
  }
}
</script>

<template>
  <div class="footer_page">
  <div class="footer">
    <div class="footer_left">
      <img v-if="!if_at_end" src="@/assets/logo.jpg" />
      <p>安徽中科谱康科技有限公司是一家专注于工业过程分析检测的整体解决方案提供商，公司核心技术人员为等离子体物理研究所光谱分析技术研究团队</p>
      <p>安徽省合肥市高新技术开发区香蒲路668号大数据产业园b3栋602室</p>
      <p>19356578402</p>
      <p>chengjing@zkpukang.com</p>
      <img src="@/assets/cert5.jpg" style="height: 200px"/>
    </div>
    <div class="footer_right">
<!--      <div class = "tengxun_map">-->
<!--      </div>-->
<!--      <img src="@/assets/map.png" />-->
      <MapContainer />
    </div>
    </div>
    <div class="footer_button">
     <p>Copyright © 安徽中科谱康科技有限公司 皖ICP备2023004459号-2.</p>
    </div>
  </div>
</template>

<style scoped>
.footer_page{
  background: #3a3a3a;
}
.footer{
  margin:auto;
  width: 1200px;
  display: flex;
  padding-top: 30px;
}
.footer_left{
  width: 40%;
}
.footer_left > *{
  color: #ffffff;
  margin: auto auto 30px 30px
}
.footer_right{
  width: 60%;
}
.footer_button{
  display: flex;
  justify-content: center;
  background: #3a3a3a;
}
.footer_button > p{
  color: #ffffff;
  display: inline-block;
  border-top: rgba(255, 255, 255, 0.5) solid 1px;
  width: 70%;
  text-align: center;
  padding: 15px 0;
}
</style>