<script>

import ProductSeriesTemp from "@/subpage/productroute/productSeriesTemp.vue";

export default {
  name:"view_page",
  components: {ProductSeriesTemp},
  data(){
    return{
      productCategorys:[
        {
          id: 1,
          name: "单通道近红外光谱分析系统",
          link: "product1"
        },{
          id: 2,
          name: "多通道近红外光谱分析系统",
          link: "product2"
        },{
          id:3,
          name: "恒温近红外光谱分析系统",
          link: "product3"
        }
      ],
      products_date:{
        product1:{
          picture: require("@/../public/resource/product_list/product_1/product_1.jpg"),
          title: "单通道近红外光谱分析系统",
          article: "在线近红外光谱（NIR）分析设备基于近红外光谱技术，通过测量样品对近红外光的吸收、反射或透射特性，分析其化学组成和物理性质。",
          productIntroduction: ["该系统主要应用于企业的生产过程，采用在线近红外光谱分析设备可以实时监测提取液中活性成分的浓度变化，帮助及时调整提取参数（如温度、时间、溶剂比例等），提高提取效率和产品质量。"],
          productCharacteristic: [
              "减少振动和温度影响：将敏感的光源和光谱仪置于远离生产线的稳定环境中，可以减少振动和温度波动对测量精度的影响，从而提高测量结果的稳定性和准确性。",
              "升级方便：分离式设计的模块化结构使得系统升级和扩展更加方便。例如，可以更换或升级光源、光谱仪等关键组件而无需更改整个系统。",
              "安装灵活：分离式设计允许将主机安装在安全、便于维护的区域，而流通池则可以安装在危险或难以接近的位置。这增加了系统的灵活性和适应性。",
              "方便维护和更换：在需要维护或更换部件时，只需处理流通池、光纤或主机的一部分，无需对整个设备进行拆卸或重新安装，减少了停机时间和维护成本。"
          ],
          applicationIndustry: "粮食、食品、饲料、化工、制药和环保等行业",
        },
        product2:{
          picture: require("@/../public/resource/product_list/product_1/product_2.jpg"),
          title: "多通道近红外光谱分析系统",
          article: "本设备拥有多通道测量能力，可以根据测量需要，一台主机可以配备多个探头，实现多个位点测量。集成多通道装置的设计减少了设备的总体占用空间，适合安装在生产线紧凑的环境中。",
          productIntroduction: ["多通道装置将多项功能集成到一个系统中，相比于分别安装多个单通道设备，可以显著降低设备采购和维护成本，提高了经济效益。"],
          productCharacteristic: [
              "多通道设计：多通道装置将多项功能集成到一个系统中，相比于分别安装多个单通道设备，可以显著降低设备采购和维护成本，提高了经济效益。",
              "减少振动和温度影响：将敏感的光源和光谱仪置于远离生产线的稳定环境中，可以减少振动和温度波动对测量精度的影响，从而提高测量结果的稳定性和准确性。",
              "升级方便：分离式设计的模块化结构使得系统升级和扩展更加方便。例如，可以更换或升级光源、光谱仪等关键组件而无需更改整个系统。",
              "安装灵活：分离式设计允许将主机安装在安全、便于维护的区域，而流通池则可以安装在危险或难以接近的位置。这增加了系统的灵活性和适应性。",
              "方便维护和更换：在需要维护或更换部件时，只需处理流通池、光纤或主机的一部分，无需对整个设备进行拆卸或重新安装，减少了停机时间和维护成本。"
          ],
          applicationIndustry: "粮食、食品、饲料、化工、制药和环保等行业",
        },
        product3:{
          picture: require("@/../public/resource/product_list/product_1/product_3.jpg"),
          title: "恒温近红外光谱分析系统",
          article: "光谱仪主机通过散热装置提供设备事宜的运行温度，可以给近红外主机提供24小时连续稳定的运行环境，可显著降低高温对检测精度的影响。",
          productCharacteristic: [
              "提供恒温环境",
              "多通道设计：多通道装置将多项功能集成到一个系统中，相比于分别安装多个单通道设备，可以显著降低设备采购和维护成本，提高了经济效益。",
              "减少振动和温度影响：将敏感的光源和光谱仪置于远离生产线的稳定环境中，可以减少振动和温度波动对测量精度的影响，从而提高测量结果的稳定性和准确性。",
              "升级方便：分离式设计的模块化结构使得系统升级和扩展更加方便。例如，可以更换或升级光源、光谱仪等关键组件而无需更改整个系统。",
              "安装灵活：分离式设计允许将主机安装在安全、便于维护的区域，而流通池则可以安装在危险或难以接近的位置。这增加了系统的灵活性和适应性。",
              "方便维护和更换：在需要维护或更换部件时，只需处理流通池、光纤或主机的一部分，无需对整个设备进行拆卸或重新安装，减少了停机时间和维护成本。"
          ],
          applicationIndustry: "粮食、食品、饲料、化工、制药和环保等行业",
        },
      },
      product_date: {}    }
  },
  methods:{
    switch_page(link){
      this.product_date = this.products_date[link]
    }
  },
  created() {
    this.switch_page("product1")
  }
}
</script>


<template>
<div class="product_box">
 <div class="product_left">
   <div class="title">
     <p>产品系列</p>
     <p>PRODUCT SERIES</p>
   </div>
   <ul class="product_list">
    <li @click="switch_page(product.link)" v-for="product in productCategorys" :key="product.id"><a>{{product.name}}</a></li>
   </ul>
 </div>
  <div class="product_right">
    <product-series-temp :product_date= "product_date" />
  </div>
</div>
</template>

<style>
.product_box{
  width: 1400px;
  margin: auto;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
.product_left{
  width: 27%;
}
.product_left > .title{
  background-color: #3a3a3a;
  padding: 20px 0 20px 40px;
}
.product_left > .title > p{
  font-size: xx-large;
  color: white;
}
.product_left > .title > p:last-child{
  font-size: larger;
}
.product_left > ul{
  margin-top: 20px;
}
.product_left > ul > li{
  cursor: pointer;
  list-style-type: none;
  font-size: large;
  padding: 10px 0 10px 40px;
  border-bottom: #63636c solid 1px;
  transition: ease-in-out 0.3s background-color;
}
.product_left > ul > li > a{
  color: #63636c;
  transition: ease-in-out 0.3s color;
}
.product_left > ul > li:hover{
  background-color: #63636c;
}
.product_left > ul > li:hover > a{
  color: white;
}
.product_right{
  width: 70%;
}

</style>