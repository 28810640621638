<script setup>

</script>

<template>
  <div class="introd_box">
    <h3 class="title">公司简介</h3>
    <div class="article">
      <p>安徽中科谱康科技有限公司是一家专注于工业过程分析检测的整体解决方案提供商，业务范围涵盖了自主研发和生产的多系列高端近红外光谱分析系统，公司核心技术人员为等离子体物理研究所光谱分析技术研究团队。</p>
      <p>针对当前化工、医药制造和食品加工等领域产业升级的迫切需求，公司致力于智能化工业在线监测所需的高品质光谱分析设备的研发、生产、销售及技术支持，为客户提供优质稳定可靠的智能化分析技术、设备及整体解决方案。</p>
      <p>公司成立至今获得了合肥市科创集团种子基金的投资，安徽省留学人员来皖创业项目等支持，同时获得了国家级高新技术企业、大数据企业、深科技企业、雏鹰企业、科技型中小企业、创新型中小企业等荣誉称号。</p>
    </div>
  </div>
</template>
<style scoped>
.introd_box .title{
  margin: 0 auto 20px auto;
  width: 20%;
  font-size: xx-large;
}
.introd_box .article{
  padding: 30px;
  background: url("../../assets/about_temple/back.png") no-repeat ;
  background-size: contain;
  height: 350px;
}
.introd_box .article > p{
  line-height: 28px;
  text-indent: 40px;
  font-size: 14px;
}
.introd_box .article > p:first-child{
  width: 76%;
}
.introd_box .article > p:nth-child(2){
  width: 65%;
}
.introd_box .article > p:last-child{
  width: 55%;
}

</style>