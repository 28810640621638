<script setup>
import {ref} from "vue";

const menu_text = ref([
  { name: "index", text: "首页", link: "/" },
  { name: "about", text: "关于谱康", link: "/about" },
  { name: "technology", text: "产品技术", link: "/product" },
  { name: "plan", text: "解决方案", link: "/solution" },
  { name: "news", text: "新闻中心", link: "/news" },
  { name: "contact", text: "联系我们", link: "/contact" },
])

</script>

<template>
<div class="footer_other">
  <ul class="footer_other_menu">
    <li v-for="text in menu_text" :key="text.name"><a :href="text.link">{{text.text}}</a> </li>
  </ul>
  <p class="footer_tt">Copyright © 安徽中科谱康科技有限公司 皖ICP备2023004459号-2.</p>
</div>
</template>

<style scoped>
.footer_other{
  background-color: #3a3a3a;
  margin-top: 30px;
}
.footer_other_menu{
  width: 80%;
  display: flex;
  margin: auto;
  justify-content: center;
}
.footer_other_menu > li{
  list-style-type: none;
  margin: 20px 20px 20px 0;
}
.footer_other_menu > li:not(:first-child){
  border-left: white 1px solid;
  padding-left: 20px;
}
.footer_other_menu > li > a{
  color: white;
  text-decoration: none;
}
.footer_other_menu > li > a:hover{
  color: #ffb300;
}
.footer_tt{
  text-align: center;
  margin: auto;
  color: white;
  height: 50px;
}
</style>