<script setup>
import {ref} from "vue";

const patent_imgs = ref([
    'patent_1.png',
    'patent_2.png',
    'patent_3.png',
    'patent_4.png',
    'patent_5.png',
    'patent_6.png',
    'patent_7.png',
])

const copyright_imgs = ref([
    'patent_8.png',
    'patent_9.png',
    'patent_10.png',
    'patent_11.png',
    'patent_12.png',
    'patent_13.png',
])
</script>

<template>
<div class="patent_box">
  <h2>专利护航</h2>
  <div class="patent_display">
    <h3>发明专利</h3>
    <div class="patent_show">
      <img v-for="(img, index) in patent_imgs" :key="index" :src="require(`@/assets/about_temple/${img}`)" />
    </div>
    <h3>软件著作权</h3>
    <div class="copyright_show">
      <img v-for="(img, index) in copyright_imgs" :key="index" :src="require(`@/assets/about_temple/${img}`)" />
    </div>
  </div>
</div>
</template>

<style scoped>
.patent_box{
  width: 90%;
  margin: auto;
}
.patent_box > h2{
  font-size: xx-large;
  text-align: center;
}
.patent_display > h3{
  margin: 20px auto 30px 20px;
}
.patent_show, .copyright_show{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.patent_show > img, .copyright_show > img{
  margin-bottom: 30px;
  margin-left: 40px;
}
</style>