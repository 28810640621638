import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";

const app = createApp(App)

// 对接口的访问
// app.config.globalProperties.backURL = "http://123.60.70.104/api"
window.globalbackURL = "https://www.cas-pukang.com/api";
// window.globalbackURL = "http://127.0.0.1:5000";
app.use(router).mount("#app")

// createApp(App).mount('#app')
// createApp(App).use(router).mount("#app")