<template>
  <div>
    <div id="container"></div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';

export default {
  name: "MapContainer",
  data() {
    return {
      map: null //初始化 map 对象
    }
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "7702c617546d633b166307038728a865", //此处填入我们注册账号后获取的Key
        version: "2.0", //指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''], //需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("container", { //设置地图容器id
          viewMode: "2D", //是否为3D地图模式
          zoom: 17, //初始化地图级别
          center: [117.127793,31.800205], //初始化地图中心点位置
        });

        const marker = new AMap.Marker({
          position: [117.127793,31.800205],
          title: "安徽中科谱康"
        });
        this.map.add(marker)
      }).catch(e => {
        console.log(e);
      })


    },
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  }
}
</script>

<style>
#container {
  width: 80%;
  height: 550px;
  margin: auto;
}
</style>