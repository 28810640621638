<template>
  <div class="header-top-box">
    <ul class="header-top-links">
      <li v-for="linkItem in linkItems" :key="linkItem.name">
        <img :src="linkItem.icoPath" />
        <a :href="linkItem.webPath">
          {{linkItem.text}}
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "head_top",
  data(){
    return{
      linkItems:[
        {
          name: "电话",
          webPath:"#",
          text: "+086 193 5657 8402",
          icoPath: require("@/assets/hand_top/telephone.png"),
        },
        {
          name: "邮箱",
          webPath: "#",
          text: "chengjing@zkpukang.com",
          icoPath: require("@/assets/hand_top/mail.png"),
        },
        {
          name: "工作日期",
          webPath: "#",
          text: "周一 - 周五 9.00 - 18.00",
          icoPath: require("@/assets/hand_top/clock.png"),
        }
      ]

    }
  }
}
</script>

<style>
.header-top-box{
  background: #2b2b35;
}
.header-top-links{
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  padding: 13px;
  width: 1000px;
  margin: auto;
}
@media only screen and (max-width: 991px){
  .header-top-links{
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 10px;
  }
}
.header-top-links li{
  padding: 0 30px;
  font-size: 13px;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px){
  .header-top-links li {
    padding: 0 12px;
    font-size: 13px;
  }
}
@media only screen and (max-width: 767px) {
  .header-top-links li {
    padding: 0 12px;
    font-size: 13px;
    border-left: 0px;
    margin-bottom: 5px;
  }
}
.header-top-links li *{
  color: #ffffff
}
.header-top-links a{
  text-decoration: none;
  transition: color 1s;
}
.header-top-links a:hover{
  color: #ffb300
}
.header-top-links img{
  height: 20px;
  margin-right: 10px;
}
</style>