<script>
import CompanyIntroduction from "@/subpage/aboutroute/companyIntroduction.vue";
import HonourView from "@/subpage/aboutroute/honourView.vue";
import PatentPage from "@/subpage/aboutroute/patentPage.vue";

export default {
  name:"about_page",
  components: {PatentPage, HonourView, CompanyIntroduction},
  props: {
    id: String
  },
  data(){
    return{
      temTexts:[{
        id: "1",
        title: "公司简介",
      },{
        id: "2",
        title: "资质荣誉",
      },{
        id: "3",
        title: "专利护航",
      }],
      activeID: null,
    }
  },
  methods:{
    modify_template(id){
      this.activeID = id
    }
  },
  mounted() {
    this.modify_template(this.id)
  }
}
</script>
<template>
  <div class="about_view_box">
  <div class="about_view">
    <div class="about_title">
      <p>公司介绍</p>
      <p>Company Introduction</p>
    </div>
    <div class="about_article">
    <div class="about_view_left">
      <ul class="about_menu">
        <li @click="modify_template(temText.id)" v-for="temText in temTexts" :key="temText.id" class="about_menu_op" :class="{activate:activeID === temText.id}"><a>{{temText.title}}</a></li>
      </ul>
    </div>
    <div class="about_view_right">
      <company-introduction v-if="activeID === '1'" />
      <honour-view v-if="activeID === '2'"/>
      <patent-page v-if="activeID === '3'" />
    </div>
    </div>
  </div>
  </div>
</template>

<style scoped>
.about_view{
  width: 1100px;
  margin: auto;
}
.about_title{
  margin: 20px 10px;
  padding-bottom: 20px;
  border-bottom: solid rgba(99, 99, 108, 0.47) 1px;
}
.about_title p{
  display: inline-block;
  padding: 0 30px;
  font-size: x-large;
}
.about_title p:last-child{
  float: right;
  color: rgba(99, 99, 108, 0.47);
}

.about_article{
  display: flex;
  justify-content: space-between;
}
.about_view_left{
  display: inline-block;
  width: 13%;
}
.about_view_right{
  display: inline-block;
  width: 83%;
}
.about_menu_op{
  list-style-type: none;
  border-bottom: solid rgba(99, 99, 108, 0.47) 1px;
  padding: 15px 0;
  cursor: pointer;
  transition: ease-in-out 0.3s background-color;
}
.about_menu_op a{
  text-decoration: none;
  color: #63636c;
  padding-left: 15px;
  font-size: larger;
  transition: ease-in-out 0.3s color;
}

.about_menu_op:hover{
  background-color: #63636c;
}
.about_menu_op:hover a{
  color: white;
}
.activate a:before{
  content: "";
  display: inline-block;
  background-color: #f19204;
  width: 5px;
  height: 15px;
  margin-right: 10px;
}
.activate a{
  padding-left: 0px;
}

@media screen and (max-width: 1100px){
  .about_view{
    width: 100%;
  }
}
</style>