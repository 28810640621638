<script setup>
import head_top from "@/components/head_top.vue";
import head_bottom from "@/components/head_bottom.vue";
import viewpage from "@/subpage/aboutroute/viewpage.vue";
import Page_footer_other from "@/components/page_footer_other.vue";
import {useRoute} from "vue-router";

const route = useRoute();

const aa = route.query.id
// console.log(aa)
</script>

<template>
  <head_top />
  <head_bottom />
  <viewpage :id="aa" />
  <page_footer_other />
</template>
